export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const toggleField = (value, element, container) => {
  if (isEmpty(value)) {
    $(container).addClass('collapse')
  } else {
    $(element).text(value)
    $(container).removeClass('collapse')
  }
}

export const toggleFieldWithHtml = (toggleValue, htmlValue, element, container) => {
  if (isEmpty(toggleValue)) {
    $(container).addClass('collapse')
  } else {
    $(element).html(htmlValue)
    $(container).removeClass('collapse')
  }
}

export const isEmpty = subject => {
  if ((typeof subject) === 'object' && subject !== null) return Object.keys(subject).length === 0
  return (!subject || subject.length === 0)
}

export const csrfToken = () => {
  return document.head.querySelector('meta[name="csrf-token"]')?.content
}

export const isTrue = val => (val === 'true' || val === true)

export const updateQueryStringParameter = (uri, key, value) => {
  let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  let separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

export const truncate = (str, num) => (str.length > num ? str.slice(0, num) + "..." : str)

export const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const ajaxForm = (form, onSuccess) => {
  form.submit((e) => {
    e.preventDefault()

    const form = $(e.currentTarget)
    const data = form.serialize();
    $.ajax({
      type: "post",
      url: form.attr('action'),
      data: data,
      dataType: "json",
      success: onSuccess
    })
  })
}

export const fetchJSON = (url, method, data) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      type: method,
      url: url,
      data: data,
      dataType : "json",
      contentType: "application/json;",
      headers: {
        'X-CSRF-Token': csrfToken()
      }
    }).then(
      (response) => { resolve(response); },
      (error) => { reject([]); }
    );
  });
}

export const randHex = (len = 10) => {
  const maxlen = 8
  const min = Math.pow(16, Math.min(len, maxlen) - 1)
  const max = Math.pow(16, Math.min(len, maxlen)) - 1
  const n = Math.floor( Math.random() * (max - min + 1) ) + min
  let r = n.toString(16)
  while ( r.length < len ) {
    r = r + randHex( len - maxlen );
  }
  return r;
};

export const dataOrNA = (str, stub = '') => ((!str || str === '') ? stub : str)

export const capitalize = (str) => (str.charAt(0).toUpperCase() + str.slice(1))

export const getPropertyByPath = (obj, path) => (path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj))

export const setPropertyByPath = (obj, path, value) => {
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

  // modifies the initial object
  // handles [] at the end for multiple values
  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined && i !== pathArray.length - 1) acc[key] = {}
    if (i !== pathArray.length - 1) return acc[key]

    acc[key] = path.endsWith('[]') ? (acc[key] || []).concat([value]) : value
  }, obj)
}

export const selectedIds = objects => {
  let ids = [];
  objects.forEach((v, i) => {
    if (v.checked)
      ids = [...ids, i]
  })
  return ids
}

export const checkedCount = objects => {
  let count = 0
  objects.forEach(v => {
    if (v.checked) { count++ }
  })
  return count
}

export const availableContentSpace = () => {
  const content = document.getElementById('kt_content')
  const footer = document.getElementById('kt_footer')
  const contentVerticalPaddings = parseInt(getComputedStyle(content).paddingBottom ) + parseInt(getComputedStyle(content).paddingTop)

  return window.innerHeight - content.offsetTop - footer.offsetHeight - contentVerticalPaddings
}

export const parseFloatOr0 = val => {
  const parsed = parseFloat(val)

  return isNaN(parsed) || parsed === Infinity ? 0 : parsed
}

export const generateHexColor = () => (
  '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
)

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => { callback(...args) }, wait)
  };
}

export const initEscButtonEvent = (elem) => {
  $(document).keyup(function(e) {
    if (e.key === "Escape")
      $(elem).trigger('click');
  });
}

export const fetchConfig = async () => {
  const res = await gon.mainConfig
  return await res.clone().json()
}

export const groupBy = (array, keyFunc) => (
  array.reduce((result, currentValue) => {
    const key = typeof keyFunc === 'function' ? keyFunc(currentValue) : currentValue[keyFunc];
    (result[key] = result[key] || []).push(currentValue);
    return result;
  }, {})
)

export const getFormData = (obj = {}, formData = new FormData(), key = '') => {
  if (!([Array, File, Object].includes(obj.constructor))) {
    return formData;
  }

  // Handle File recursive
  if (obj instanceof File) {
    formData.append(key, obj);
    return formData;
  }

  for (const prop in obj) {
    // Validate value type
    if (obj[prop] && !([String, Number, Boolean, Array, Object, File].includes(obj[prop].constructor))) {
      continue;
    }

    // Set deep index of prop
    const deepKey = key ? key + `[${prop}]` : prop;

    // Handle array
    if (Array.isArray(obj[prop])) {
      obj[prop].forEach((item, index) => {
        getFormData(item, formData, `${deepKey}[${index}]`);
      })
      continue;
    }

    (typeof obj[prop] === 'object' && obj[prop] && obj[prop].constructor === Object)
      ? getFormData(obj[prop], formData, deepKey) // Handle object
      : formData.append(deepKey, [undefined, null].includes(obj[prop]) ? '' : obj[prop]) // Handle string, number, boolean
  }

  return formData;
}

export const formatPhoneNumber = (phone) => ((phone && phone.length > 0) ? phone.replace(/[^0-9+\-]/g, '') : '');

export const initMultiSelectWithSearch = elem => {
  if (!elem || !elem.length) return

  var Utils = $.fn.select2.amd.require('select2/utils');
  var Dropdown = $.fn.select2.amd.require('select2/dropdown');
  var DropdownSearch = $.fn.select2.amd.require('select2/dropdown/search');
  var CloseOnSelect = $.fn.select2.amd.require('select2/dropdown/closeOnSelect');
  var AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');
  var dropdownAdapter = Utils.Decorate(Utils.Decorate(Utils.Decorate(Dropdown, DropdownSearch), CloseOnSelect), AttachBody);
  var currentOptions = elem.data('select2').options.options;
  var newOptions = { ...currentOptions, dropdownAdapter: dropdownAdapter };

  elem.select2('destroy').select2(newOptions);
}
