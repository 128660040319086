import 'bootstrap'

import '@/user'
import { initPasswordToggle, initPasswordValidation } from "../src/helpers/view";

require('@rails/ujs').start()

$(() => {
  if ($('body').hasClass('passwords edit')) {
    initPasswordValidation('#user_password');

    $('#user_password_confirmation').on('input', function() {
      var confirmPassword = $(this).val();
      var password = $('#user_password').val();

      if (confirmPassword == password) {
        $('.confirm-check-msg').hide();
      } else {
        $('.confirm-check-msg').show();
      }
    });
  }

  initPasswordToggle()
})
